import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.replace.js";
// 整体概览
export function createOverallBlocks(arr) {
  if (!arr) return [];
  var init = [{
    title: '预估收益',
    value: 0,
    tooltip: 'CPS订单在未结算下，我方技术服务费和权益订单的实际差价收益'
  }, {
    title: '结算收益',
    value: 0,
    tooltip: 'CPS订在已结算下，我方技术服务费和权益订单的实际差价收益'
  }, {
    title: '预估佣金',
    value: 0,
    tooltip: '在选定条件内，订单以下单付款金额为基数，扣除上游服务费服务费后，预估可能获得的佣金收入。因买家退款等原因，可能与结算预估佣金不一致'
  }, {
    title: '结算佣金',
    value: 0,
    tooltip: '在选定条件内，订单以确认收货的付款金额为基数，，扣除上游服务费服务费后，预估可能获得的佣金收入。因订单退款、违规等原因，可能与最终收入不一样，具体以实际为准'
  }, {
    title: '支付金额',
    value: 0,
    tooltip: 'CPS订单和权益订单的全部支付金额，包括有效和失效订单'
  }, {
    title: '订单量',
    value: 0,
    tooltip: 'CPS订单和权益订单的全部订单数量，包括有效和失效订单'
  }];
  return init.map(function (a, i) {
    return _objectSpread(_objectSpread({}, a), {}, {
      value: arr[i] || 0
    });
  });
}
export function createOverallClientBlocks(arr) {
  if (!arr) return [];
  var init = [{
    title: '客户数',
    value: 0,
    tooltip: '在选定时间内，累计注册推老板后台的客户数量'
  }, {
    title: '正式客户',
    value: 0,
    tooltip: '在选定时间内，该客户的任一应用有开通正式版的即为正式客户'
  }, {
    title: '试用客户',
    value: 0,
    tooltip: '在选定时间内，该客户的所有应用均尚未开通正式版的即为试用客户'
  }, {
    title: '活跃客户',
    value: 0,
    tooltip: '在选定时间内，推老板账号在后台有操作行为的客户'
  }];
  return init.map(function (a, i) {
    return _objectSpread(_objectSpread({}, a), {}, {
      value: arr[i] || 0
    });
  });
}
export function createOverallAppBlocks(arr) {
  if (!arr) return [];
  var init = [{
    title: '应用数',
    value: 0,
    tooltip: '在选定时间内，所有在推老板后台已创建的应用数量'
  }, {
    title: '活跃应用',
    value: 0,
    tooltip: '在选定时间内，推老板账号在后台有操作行为的所有已创建的应用'
  }, {
    title: '用户数',
    value: 0,
    tooltip: '在选定时间内，所有应用下已注册的用户数'
  }, {
    title: '活跃用户',
    value: 0,
    tooltip: '在选定时间内，所有应用下浏览过任意页面的用户数'
  }];
  return init.map(function (a, i) {
    return _objectSpread(_objectSpread({}, a), {}, {
      value: arr[i] || 0
    });
  });
}
/**
 * 创建概览图表 数据及选项
 * @param {Array} x []
 * @param {Object} y { name, data }
 * @returns echarts option
 */

export function createOverallChartData(x, y) {
  var selected = {};
  var yAxis = [];
  var offsetCount = 0;
  y.forEach(function (a, i) {
    selected[a.name] = i === 1 || i === 3; // 从第2条y轴开始记offset，第3条y轴开始用
    // 就最多俩吧

    if (i > 1) return;
    yAxis[i] = {
      offset: offsetCount
    };
    var offset = i < 1 ? 0 : 8 * a.data.slice().sort(function (a, b) {
      return b - a;
    })[0].toFixed(0).length + 5;
    offsetCount += offset;
  });
  return {
    legend: {
      left: '0',
      top: 5,
      icon: 'rect',
      itemWidth: 10,
      itemHeight: 2,
      itemGap: 22,
      selected: selected
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: x
    },
    yAxis: yAxis,
    // 多个 y 轴
    grid: {
      width: '100%',
      top: 60,
      bottom: 10
    },
    series: y.map(function (a, i) {
      return {
        name: a.name,
        yAxisIndex: i > 1 ? 0 : i,
        smooth: true,
        showSymbol: true,
        data: a.data,
        areaStyle: {
          opacity: .2
        }
      };
    })
  };
} // CPS

export function createCpsBlocks(arr) {
  if (!arr) return [];
  var init = [{
    id: 1,
    title: '预估收益',
    value: 0,
    tooltip: '在选定时间内，所有CPS订单产生的预估收益'
  }, {
    id: 2,
    title: '结算收益',
    value: 0,
    tooltip: '在选定时间内，所有CPS订单产生的结算收益'
  }, {
    id: 3,
    title: '预估佣金',
    value: 0
  }, {
    id: 4,
    title: '结算佣金',
    value: 0
  }, {
    id: 5,
    title: '有效支付金额',
    value: 0,
    tooltip: '在选定时间内，通过推老板平台下单且订单状态正常的所有有效订单支付金额总和'
  }, {
    id: 6,
    title: '有效订单量',
    value: 0,
    tooltip: '在选定时间内，通过推老板平台下单，订单状态为已付款、已收货、已结算且状态正常的的订单数量之和'
  }, {
    id: 7,
    title: '失效订单量',
    value: 0,
    tooltip: '在选定时间内，通过推老板平台下单，订单状态异常（包括超时未付款、取消订单、付款后发起退款成功、未支付成功的预售订单、维权订单、其他失效订单）的订单数量之和'
  }];
  return init.map(function (a, i) {
    return _objectSpread(_objectSpread({}, a), {}, {
      value: arr[i] || 0
    });
  });
}

function getPercentStr(value, total) {
  return (100 * value / total).toFixed(2) + '%';
}
/**
 * 创建概览图表 数据及选项
 * @param {Object}
 *   x: [],
 *   y { name, data },
 *   type line | pie,
 *   title: String
 * @returns echarts option
 */


export function createCpsChartData(options) {
  var x = options.x,
      y = options.y,
      _options$type = options.type,
      type = _options$type === void 0 ? 'line' : _options$type,
      _options$title = options.title,
      title = _options$title === void 0 ? '' : _options$title,
      _options$names = options.names,
      names = _options$names === void 0 ? [] : _options$names,
      _options$data = options.data,
      data = _options$data === void 0 ? [] : _options$data;

  if (type === 'line') {
    return {
      legend: {
        left: '0',
        top: 5,
        icon: 'rect',
        itemWidth: 10,
        itemHeight: 2,
        itemGap: 22
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: x
      },
      grid: {
        width: '100%',
        top: 50,
        bottom: 10
      },
      series: y.map(function (a) {
        return {
          name: a.name,
          smooth: true,
          showSymbol: true,
          data: a.data,
          areaStyle: {
            opacity: .2
          }
        };
      })
    };
  }

  var sum = data.reduce(function (a, b) {
    return a + b;
  }, 0);
  var isMoney = title && title.indexOf('量') === -1;
  return {
    sum: Number(sum.toFixed(2)),
    unit: !isMoney ? '个' : '元',
    centerName: title,
    legend: {
      orient: 'vertical',
      formatter: function formatter(name) {
        var arr = name.split(' ');
        return "{a|".concat(arr[0], "} {b|").concat(arr[1], " ").concat(arr[2], "}  {a|").concat(arr[3], "}");
      },
      textStyle: {
        rich: {
          b: {
            color: '#666'
          }
        }
      }
    },
    series: [{
      data: data.map(function (a, i) {
        var name = (names[i] || '').replace(/ /g, '');
        return {
          name: "".concat(name, " \uFF5C ").concat(getPercentStr(a, sum), " ").concat(isMoney ? '¥' : '').concat(a),
          value: a
        };
      })
    }]
  };
} // 权益

export function createQyBlocks(arr) {
  if (!arr) return [];
  var init = [{
    id: 1,
    title: '收益',
    value: 0,
    tooltip: '在选定时间内，所有权益订单产生的收益，即推老板给客户的成本价减去对接上游平台给到的采购价'
  }, {
    id: 4,
    title: '有效支付金额',
    value: 0,
    tooltip: '在选定时间内，通过推老板平台下单，订单状态为处理中、已成功状态的订单金额之和'
  }, {
    id: 5,
    title: '有效订单量',
    value: 0,
    tooltip: '在选定时间内，通过推老板平台下单，订单状态为处理中、已成功状态的订单数量之和'
  }, {
    id: 6,
    title: '失效订单量',
    value: 0,
    tooltip: '在选定时间内，通过推老板平台下单，订单状态为已失效状态的订单数量之和'
  }];
  return init.map(function (a, i) {
    return _objectSpread(_objectSpread({}, a), {}, {
      value: arr[i] || 0
    });
  });
}